.instagram-carousel {
    margin: 20px 0;
    text-align: center;
  }
  
  .instagram-carousel h1 {
    font-family: 'Kaisei Opti', serif; 
    letter-spacing: -2.6px;
    margin-bottom: 20px;
  }
  
  .instagram-post {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .instagram-post img,
  .instagram-post video {
    width: 100%;
    height: auto;
    max-width: 300px;
    border-radius: 10px;
  }
  
  .instagram-post p {
    margin-top: 10px;
    font-size: 14px;
    color: #333;
    text-align: center;
  }
  