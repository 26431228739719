/* Legal Document Container Styles */
.legal-document-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0; 
  background-color: #F5ECE2; 
  padding: 20px; 
  box-sizing: border-box;
  overflow-y: auto; 
  padding-top: 100px;
}

/* Navigation Links Styles */
.navigation-links {
  flex: 1; 
  display: flex;
  flex-direction: row;
  align-items: start; 
  padding-right: 20px; 
  border-right: 1px solid #BB9C71; 
  height: fit-content; 
}

.navigation-links div:first-child {
  margin-right: 50px; 
  margin-left: 20px;
}

/* Content Area Styles */
.content-area {
  flex: 1; 
  padding: 0 20px; 
  overflow-y: auto; 
}

/* Read More Link Styles */
.read-more-link {
  display: inline; 
  position: relative;
  cursor: pointer;
  text-decoration: none;
  color: #BB9C71;
  font-size: 0.9em;
  margin-top: 1rem;
  padding-bottom: 0.25rem;
  transition: all 0.3s ease-in-out; 
}

.read-more-link::after,
.read-more-link::before {
  content: '';
  position: absolute;
  transition: all 0.3s ease-in-out;
}

.read-more-link::after {
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #BB9C71;
  visibility: hidden;
  border-radius: 5px;
  transform: scaleX(0);
  transform-origin: left;
}

.read-more-link:hover::after,
.read-more-link.active::after {
  visibility: visible;
  transform: scaleX(1);
}

.read-more-link::before {
  top: 50%;
  right: -20px;
  transform: translate(0, -50%);
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 2px solid #BB9C71;
}

.read-more-link:hover::before,
.read-more-link.active::before {
  background-color: #BB9C71;
}

/* Responsive Design Adjustments */
@media (min-width: 1200px) {
  .privacy-content p,
  .terms-content p {
    font-size: 20px; 
  }
}

@media (max-width: 768px) {
  .legal-document-container {
    flex-direction: column;
  }

  .navigation-links {
    flex-direction: row; 
    justify-content: flex-start;
    padding-right: 0;
    border-right: none; 
    border-bottom: 2px solid #BB9C71;
    margin-bottom: 20px;
  }

  /* Ensure child elements don't overflow */
  .navigation-links div {
    padding: 0;
    margin-right: 0; 
  }

  /* Adjust content area padding if needed */
  .content-area {
    padding: 20px; 
  }

  .privacy-content p,
  .terms-content p {
    font-size: 20px; 
  }

}

