/* Projects.css */
/* Import Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Kaisei+Opti&display=swap");

/* Page Specific Styles */
.projects-page {
  overflow: hidden; /* Prevents horizontal scroll */
}

/* Fixed Background Image */
.fixed-background {
  background-image: url("../assets/projects-fixed.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  margin-bottom: -50vh;
}

/* Content Container */
.content-container {
  position: relative;
  z-index: 1;
  background: transparent;
  padding-top: 50vh;
}

/* Project Titles and Categories */
.project-title,
.project-category {
  font-family: "Kaisei Opti", serif;
  text-align: start;
  text-transform: none; 
  margin-top: 8px;
}

/* Portfolio Title and */
/* Project titles size */
.projects-container .project-title {
  font-family: "Kaisei Opti", serif;
  letter-spacing: -2.6px;
  text-align: center;
  margin-bottom: 80px;
  background-color: transparent;
}

/* Title Container */
.title-container {
  background-color: #f5ece2;
  padding: 20px 0;
  text-align: center;
}

/* Projects Container Grid */
.projects-container {
  padding: 30px 40px;
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  grid-gap: 20px;
  margin: 0 auto; 
  background-color: #f5ece2;
}

.about-contact {
  margin-top: 0px;
}

/* Project Item Styles */
.project-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
  aspect-ratio: 3 / 4;
}

/* Overlay Effect for Project Items */
.project-item {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.project-image-container {
  position: relative;
  overflow: hidden;
}

.project-image-container img {
  width: 100%;
  height: auto;
  display: block;
}

.project-hover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(245, 236, 226, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transform: scale(0);
}

.project-image-container:hover .project-hover-overlay {
  opacity: 1;
  transform: scale(1);
}

.project-hover-overlay h1 {
  color: #333;
  font-size: 2rem;
  background-color: transparent;
  padding: 1rem 2rem;
}

/* Responsive Design Adjustments */
/* Large Screens Adjustments */
@media only screen and (min-width: 1200px) {
  .fixed-background {
    background-attachment: fixed;
    background-size: cover;
  }

  .project-title,
  .project-category {
    font-size: 14px;
  }
}

/* Adjustments for Smaller Screens */
@media (max-width: 1200px) {
  .fixed-background {
    background-attachment: scroll;
    background-size: auto 100%;
  }

  .projects-container {
    grid-template-columns: 1fr;
  }

  .project-image-container .project-title{
    margin-left: 0;
  }
}
