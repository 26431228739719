/* ContactForm.css */
.contact-container {
  display: flex;
  justify-content: space-between;
  background-color: #616060; /* Dark background aas per brand guidelines */
  color: white;
  padding: 40px;
  font-family: "Lato", sans-serif;
  position: relative;
  padding-top: 100px;
}

/* Contact Information Styles */
.contact-info {
  width: 50%;
  font-family: "Kaisei Opti", serif;
}

.contact-info h1 {
  margin-bottom: 20px;
  color: #f5ece2;
  letter-spacing: -2.6px;
}

.additional-info {
  padding-bottom: 30px;
}

.contact-info p,
.additional-info p {
  color: #f5ece2;
  margin-bottom: 10px;
}

.additional-info a {
  color: inherit; 
  text-decoration: none; 
}

/* Form Styling */
.contact-form {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

/* Label and Input Field Styling */
.label,
.input,
.textarea {
  color: #f5ece2;
  font-weight: bold;
}

/* Text area styling */
.input,
.textarea {
  background-color: transparent !important; 
  border: none !important; 
  border-bottom: 1px solid #777 !important;
  color: white !important;
  padding: 10px 0 !important; 
}

.input:focus,
.textarea:focus {
  outline: none !important;
  border-bottom: 1px solid #bb9c71 !important;
  box-shadow: none !important; 
}

/* Overrides the autofill background color in Webkit browsers like Safari */
.input:-webkit-autofill,
.textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #616060 inset !important;
  box-shadow: 0 0 0px 1000px #616060 inset !important;
  -webkit-text-fill-color: white !important;
}
/* Placeholder Styles */
.input::placeholder,
.textarea::placeholder {
  color: #bbb;
  opacity: 1;
}

/* Invalid Input Styles */
.input.is-invalid,
.textarea.is-invalid {
  border-bottom: 1px solid #ff0000; 
}

.input:-ms-input-placeholder,
.textarea:-ms-input-placeholder {
  color: #bbb;
}

.input::-ms-input-placeholder,
.textarea::-ms-input-placeholder {
  color: #bbb;
}

/* This ensures that the label doesn't overlap with the input values */
.label {
  pointer-events: none;
  user-select: none;
}

/* Submit button styling */
.submit-button {
  background-color: transparent !important;
  border: none !important;
  cursor: pointer;
  padding: 10px 20px;
  text-decoration: underline;
  color: #f5ece2;
  font-size: 16px;
  line-height: normal;
  margin: 0;
  transition: all 0.3s ease;
}

.submit-button:hover,
.submit-button:focus {
  text-decoration: none;
  background-color: #bb9c71 !important;
  color: #f5ece2;
}

/* Alert Message Styles */
.alert {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1050;
  border: 1px solid transparent;
  border-radius: 5px;
  background-color: #f5ece2; /* Updated color to match the header */
  color: #616060; /* Contrasting text color */
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: inline-block;
  font-size: 0.9rem;
  width: auto;
  text-align: center;
}

.alert-success {
  animation: fadeOut 5s ease-in-out forwards;
  animation-delay: 2s;
}

/* Contact Bottom Image Styles */
.contact-bottom-image {
  width: 100%;
  display: block;
  clear: both;
}

.contact-bottom-image img {
  width: 100%;
  height: auto;
  display: block;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Responsive Design Adjustments */
/* Large screens adjustment */
@media (min-width: 1200px) {
  .contact-info h1 {
    font-size: 2.5rem; 
  }

  .contact-info p,
  .additional-info p,
  .contact-form .label,
  .contact-form .input,
  .contact-form .textarea {
    font-size: 20px; 
  }

  .submit-button {
    font-size: 20px; 
  }
}

@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    align-items: center;
  }

  .contact-info,
  .contact-form {
    width: 100%;
  }

  .submit-button {
    align-self: center;
  }
}
