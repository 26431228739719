/* Import and define fonts */
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
@font-face {
  font-family: "Benguiat";
  src: url("./assets/fonts/itc_benguiat_ITC/BenguiatStd-Book.otf")
    format("opentype");
  font-weight: bold;
  font-style: normal;
}

/* Reset styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Define color variables */
:root {
  --background-color: #f5ece2;
  --accent-color: #bb9c71;
  --text-color: #616060;
}

/* Basic body styles */
body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: "Lato", sans-serif;
}

/* Ensure full width for the navbar and footer containers */
.navbar .container, .footer .container {
  width: 100%;
  max-width: none;
  padding-right: 25;
  padding-left: 25;
}

/* Navbar styles */
.navbar {
  background-color: #F5ECE2; 
  transition: background-color 0.3s;
  backdrop-filter: none;
  /* -webkit-backdrop-filter: none; */
}

/* When the user has scrolled */
.navbar.scrolled {
  background-color: rgba(245, 236, 226, 0.8); 
  backdrop-filter: blur(10px);
  /* -webkit-backdrop-filter: blur(10px); */
}

/* Centering the brand logo in the navbar */
.navbar-brand {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/* Navbar link styles */
.navbar .nav-link,
.header-icon {
  color: var(--accent-color);
  font-weight: bold;
}

.logo-font {
  font-family: "Benguiat", "Times New Roman", Times, serif !important;
  color: var(--accent-color) !important; 
  font-size: 2rem !important; 
}

/* Menu overlay styles */
.menu-overlay,
.menu-overlay-show {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("./assets/overlay-menu.PNG") no-repeat center center fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: translateY(-100%);
  z-index: 2000;
  transition: transform 1s ease-in-out;
}

.menu-overlay {
  transform: translateY(-100%);
}

.menu-overlay.show {
  transform: translateY(0);
}

/* Menu content styles */
.menu-content a {
  color: white;
  font-size: 2rem;
  padding: 1rem;
  text-decoration: none;
  text-transform: uppercase;
  margin: 0.5rem 0;
  transition: color 0.3s;
}

.menu-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-content a:hover {
  color: var(--accent-color);
}

/* Close button */
.close-icon {
  position: absolute;
  top: 20px; 
  right: 40px; 
  font-size: 30px; 
  cursor: pointer; 
  color: #fff;
  z-index: 10; 
}

.close-icon:hover {
  color: var(--accent-color);
}

/* Custom icon adjustments */
.header-icon {
  cursor: pointer;
  max-height: 35px;
  color: #BB9C71;
}

/* Padding for main content */
main {
  padding-top: 30px;
}

/* Image for the contact page */
.contact-bottom-image {
  width: 100%;
  display: block; 
}

.contact-bottom-image img {
  width: 100%;
  height: auto; 
  display: block; 
}

/* Footer styling */
.footer {
  background-color: var(--background-color);
  padding: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: auto ;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.footer-section,
.footer-logo,
.footer-bottom {
  flex: 1 1 auto;
  min-width: 160px;
  text-align: center;
}

.footer-bottom {
  padding-top: 1rem;
  border-top: 1px solid #ccc;
}

/* Footer text and logo styling */
.footer h5,
.footer p,
.footer-logo h1 {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  color: var(--accent-color);
  line-height: 1.6;
}

.footer-logo h1 {
  font-family: "Benguiat", serif;
  font-size: 2.5rem;
}

.footer-logo {
  text-align: center; 
}

.footer-icon {
  display: block; 
  margin: 0 auto;
  max-width: 100px; 
  height: auto; 
  margin-top: 1rem;
}


.header-and-first-item {
  display: flex;
  align-items: flex-start; 
  color: #555;
}

.header-and-first-item h5 {
  margin-right: 20px; 
}

.first-item-and-below p, .first-item-and-below p a {
  margin-bottom: 0.5rem;
  display: block; 
}

.first-item-and-below a:not(p a) {
  display: block; 
}

/* Responsive styling */
/* On larger screens */
@media (min-width: 1200px) {
  .footer .footer-column p, .footer  .footer-column a {
    font-size: 18px; 
  }
}

/* On medium screens */
@media (min-width: 1024px) {
  .navbar .container, .footer .container {
    max-width: 90vw; 
    padding-right: 15px;
    padding-left: 15px;
  }
}


/* Responsive styling for screens at 768px and below */
@media (max-width: 886px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
  }

  .footer-column {
    margin: 0;
    padding: 0;
    width: 100%; 
  }

  .footer-column .header-and-first-item,
  .footer-column .first-item-and-below {
    /* Remove any specific alignment styles */
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .footer-column h5,
  .footer-column p,
  .footer-column a {
    text-align: center;
    margin: 0.5rem 0;
    width: 100%; 
  }

  .footer-logo h1 {
    margin: 0;
    align-self: center;
  }

  .footer-bottom {
    text-align: center;
    width: 100%;
  }
}

/* On small screens */
@media (min-width: 768px) {
  .footer-content {
    justify-content: space-around;
  }

  .footer,
  .footer-section,
  .footer-logo {
    flex-wrap: nowrap;
    flex: none;
    flex-basis: auto;
  }
}

.footer-column p,
.footer-column a {
  color: inherit;
  font-size: 0.8rem;
  line-height: 1.6;
  text-decoration: none;
  margin-bottom: 0.5rem;
  display: block;
}

.footer-column a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .logo-font {
    margin-top: 10px; 
  }
}



