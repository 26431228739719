/* Global styles */
/* Prevent horizontal scrolling */
html, body {
  max-width: 100%;
  overflow-x: hidden;
}

/* Ensure all elements do not exceed the width of the page */
* {
  box-sizing: border-box;
}

.home-page {
  background-color: var(--background-color);
}

/* Typography styles*/
h1 {
  font-family: "Kaisei Opti", serif;
  letter-spacing: -2.6px;
  color: var(--text-color);
  margin-bottom: 50px;
  font-size: 3em;
}

.home-page h2 {
  font-size: 1.25em;
  color: var(--text-color);
}

p {
  font-family: "Lato", sans-serif;
  font-size: 0.9em;
  color: var(--text-color);
}

/* Hero section */
.home-hero {
  position: relative;
  height: 100vh;
  background: url("../assets/home-background.PNG") no-repeat center center fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.parallax-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-attachment: fixed;
  z-index: -1;
}

.home-hero-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 4em;
}

/* Image and Text Layout */
.home-specialty-image {
  width: 100%;
  height: auto;
}

.home-image-container {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0;
  margin: 0;
}

.home-hero-text {
  flex: 1;
  padding: 0 4em 0 5em;
}

/* Title and Link styles */
.title-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-left: 100px;
  padding: 0 20px; /* Add some padding to the sides */
  width: 100%;
  max-width: 800px; /* Adjust max-width as needed */
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.title-div p {
  font-size: 20px;
  margin-right: 40px;
}

/* Key Offerings */
.key-offerings-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.key-offerings-title a {
  display: block;
  margin-bottom: 10px;
}

.key-offerings-title .read-more-link {
  margin-left: 60px;
}

/* Read more link style */
.read-more-link {
  position: relative;
  display: inline-block;
  margin-bottom: 75px;
  padding: 0.5em 1em;
  text-decoration: none;
  color: var(--text-color);
  background-color: transparent;
  transition: color 0.5s ease;
}

/* Hover Animations for Links */
/* Read more link style adjustments */
.key-offerings-title .read-more-link {
  position: relative;
  display: inline-block;
  align-items: center;
  margin-bottom: 75px;
  padding: 0;
  text-decoration: none;
  color: var(--text-color);
  background-color: transparent;
}

/* The underline effect */
.key-offerings-title .read-more-link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  height: 2px;
  width: 0;
  background: #BB9C71;
  transition: width 0.3s ease-in-out;
}

/* When hovering over the link, extend the underline to the full width of the text */
.key-offerings-title .read-more-link:hover::after {
  width: 100%;
}

/* Adjust the circle position */
.key-offerings-title .read-more-link::before {
  content: '';
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translate(0, -50%);
  width: 8px;
  height: 8px;
  border: 2px solid #BB9C71;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out;
}

/* Change the circle's background on hover */
.key-offerings-title .read-more-link:hover::before {
  background-color: #BB9C71;
}

/* Content wrapper */
.home-content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4em 0;
}

/* Home Title */
.home-title {
  margin-bottom: 20px;
}

/* Home Text Half */
.home-text-half {
  flex: 1;
  padding: 0 2em;
  margin-right: 50px;
  margin-left: 20px;
}

.home-text-half h2 {
  font-size: 1.5em;
}

.home-description {
  flex: 1;
}

/* Home Image Half */
.home-image-half {
  flex: 0 0 50%;
  margin-right: 0;
  max-width: 50%;
  padding: 0;
}

/* Home Aligned Image and Video */
.home-aligned-image img,
.home-aligned-image video {
  width: 95%;
  height: auto;
  display: block;
}

/* Carousel section */
.projects-carousel, .swiper {
  width: 100%;
  margin-bottom: 50px;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Carousel Slide */
.projects-section .projects-header h1 {
  font-family: "Kaisei Opti", serif;
  letter-spacing: -2.6px;
  margin-left: 50px;
  font-size: 3em;
  color: var(--text-color);
}

/* Slide Styling */
.carousel-slide {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Carousel Image Styling */
.carousel-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* This additional container is used to handle vertical images */
.carousel-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Adjusting the image to cover the space */
.carousel-image-container img {
  height: 100%;
  width: auto;
  object-fit: cover;
}

/* Carousel Hover Overlay Styling */
.carousel-hover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(245, 236, 226, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease;
  visibility: hidden;
}

/* Show overlay on hover */
.carousel-slide:hover .carousel-hover-overlay {
  opacity: 1;
  visibility: visible;
}

/* Style for the overlay text */
.carousel-hover-overlay h1 {
  color: var(--text-color);
  font-size: 2rem;
  text-align: center;
}

/* Swiper navigation arrows customization */
.swiper-button-next,
.swiper-button-prev {
  color: #BB9C71 !important;
  filter: none !important;
  z-index: 10;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 16px;
}

/* Optionally, adjust the position and size further if needed */
.swiper-button-next,
.swiper-button-prev {
  top: 50%;
  transform: translateY(-50%);
}

/* Key Offerings Section */
.key-offerings-container {
  background-color: rgb(224, 214, 204);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.key-offerings-content {
  display: flex;
  width: 100%;
  margin-left: 50px;
}

.key-offerings-title {
  flex-basis: 50%;
}

.key-offerings-title h1 {
  font-family: "Kaisei Opti", serif;
  letter-spacing: -2.6px;
  margin-bottom: 50px;
  font-size: 3em;
  color: var(--text-color);
  padding-left: 50px;
}

.read-more-link,
.key-offerings-title a {
  display: block;
  margin-bottom: 10px;
  padding-left: 50px;
}

.key-offerings-image {
  flex-basis: 50%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.key-offerings-image img {
  object-fit: cover;
  width: auto;
  height: auto;
  align-items: right;
}

/* Extend underline hover effect for read-more-link */
.read-more-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 1px;
  bottom: 0;
  right: 0;
  background-color: #BB9C71;
  visibility: hidden;
  border-radius: 5px;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

/* Adjust width on hover to extend the underline */
.read-more-link:hover::after {
  visibility: visible;
  transform: scaleX(1);
  width: 100%;
}

/* font-size for p in title-div and home-text-half is explicitly set */
.title-div p,
.home-text-half p {
  font-size: 20px;
}

/* Override any other font-size set for p by any parent or global style */
.title-div p,
.home-text-half p {
  font-size: 20px !important;
}

/* Media query adjustments for responsiveness */
/* Large screens */
@media (max-width: 1240px) {
  .title-div p,
  .home-text-half p,
  .home-title p,
  .home-description p,
  p {
    font-size: 17px; /* Adjust font size as needed */
  }
}

@media (min-width: 1200px) {
  .title-div p,
  .home-text-half,
  .home-page p,
  .home-page a,
  .home-page .book-consultation-link {
    font-size: 20px !important;
  }
}

/* Media queries for the Key Offerings */
@media (min-width: 769px) and (max-width: 1499px) {
  .key-offerings-content {
    flex-wrap: wrap;
  }

  .key-offerings-title,
  .key-offerings-image {
    flex: 1;
    max-width: 50%;
  }

  .key-offerings-image img {
    max-width: 100%;
    height: auto;
  }
}

/* Medium devices (tablets up to 991px) */
@media (max-width: 991px) {
  .title-div p,
  .home-page p,
  .home-page a,
  .home-page .book-consultation-link {
    font-size: 17px !important;
  }
}

@media (min-width: 1024px) {
  .home-image-half {
    flex-basis: 60%;
    max-width: 60%;
  }
  .home-content-wrapper {
    justify-content: center;
  }
}

/* Tablets in landscape mode */
@media (max-width: 991px) {
  .footer-content,
  .footer-section,
  .footer-logo {
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  }

  .footer-section,
  .footer-logo {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .footer-logo {
    order: -1;
  }

  .footer-bottom {
    border-top: 1px solid #ccc;
    padding-top: 1rem;
    width: 100%;
  }
}

/* Small screens */
@media (max-width: 768px) {

  .home-hero {
    background: url("../assets/home-background.PNG") no-repeat center center;
    background-size: cover;
    background-attachment: scroll;
    height: auto;
    min-height: 50vh;
  }

  .home-hero::before {
    /*! Remove the pseudo-element since we are not doing parallax on mobile */
    content: none;
  }

  /* Hide the contact link in the header */
  .navbar .nav-link {
    display: none;
  }

  /*! Reorder the hero content so the image is on top and the text is below */
  .home-hero-content {
    flex-direction: column-reverse;
  }

  .home-content-wrapper, .projects-section .projects-header {
    padding-top: 0px;
    margin-top: 0px;
  }

  .home-content-wrapper, .title-div .read-more-link {
    padding-bottom:0px;
    margin-bottom:0px;
  }

  .key-offerings-title h1 {
    padding-left: 0;
  }

  /* Adjust the home content wrapper to stack children vertically */
  .home-content-wrapper,
  .key-offerings-content,
  .title-div,
  .home-image-half,
  .home-text-half,
  .home-image-container,
  .home-aligned-image {
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;
    margin: 20px 0;
  }

  /* Adjust padding for the text half and title div for smaller screens */
  .home-text-half,
  .title-div {
    width: calc(100% - 2em);
  }

  .title-div p  {
    margin-left: 0px; /* Add padding for smaller screens */
    margin-right:70px;
  }

  .home-text-half {
    padding: 0 3em;
  }

  .title-div p,
  .home-page p,
  .home-page a,
  .home-page .book-consultation-link {
    font-size: 14px !important; 
  }

  /* Ensure images and videos scale down to fit the width of the screen */
  .home-specialty-image,
  .home-aligned-image img,
  .home-aligned-image video,
  .key-offerings-image img {
    max-width: 100%;
    height: auto;
  }

  /* Adjust read-more links and key offerings links for smaller screens */
  .read-more-link,
  .key-offerings-title a {
    max-width: 100%;
    margin-bottom: 30px;
    padding-left: 0;
  }

  /* Order key offerings content properly */
  .key-offerings-image {
    order: 2;
  }

  .key-offerings-container {
    padding: 0;
  }

  .key-offerings-image img {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .key-offerings-title {
    order: 1;
  }

  /* Hide the 'Get In Touch' section at the bottom */
  .home-overlay .about-contact {
    display: none;
  }

  /* Fixes for specific elements if needed */
  .home-hero,
  .home-overlay,
  .home-content-wrapper,
  .home-image-half,
  .home-text-half,
  .home-image-container,
  .home-aligned-image,
  .key-offerings-container,
  .key-offerings-content,
  .key-offerings-title,
  .key-offerings-image,
  .about-contact {
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  .key-offerings-title h1,
  .key-offerings-title .read-more-link {
    text-align: center;
    padding-left: 0;
    margin-left: 0px;
  }

  .key-offerings-title {
    align-items: center;
    font-size: 11px;
  }
}
