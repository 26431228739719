/* Import fonts */
@import url("https://fonts.googleapis.com/css2?family=Kaisei+Opti&display=swap");

/* Global Styles */
.project-detail-container {
  font-family: "Kaisei Opti", serif;
}

/* Adjust global paragraph and heading colors */
p,
h1 {
  color: #444;
}

/* Background Styles */
.project-detail-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  z-index: -1;
  height: 100vh; /* Adjust if needed */
}

/* Content Styles */
.project-content {
  position: relative;
  z-index: 1;
  padding-top: 30px;
  margin-top: 100vh; 
  min-height: 100vh; 
  background: #f5ece2; 
}

/* First Section Styles */
.first-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.project-title {
  flex: 3;
  font-size: 36px; 
  margin-left: 0px;
  text-transform: none;
}

.project-description {
  flex: 2;
  margin-right: 20px;
  font-size: 20px; 
}

.featured-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: none; 
  margin: 0 auto; 
  margin-right: 50px;
  width: 50%; 
}

.featured-image {
  width: 80%; 
  height: auto; 
  object-fit: cover; 
}

/* Full-width Hero Image Styles */
.full-width-hero {
  width: 100%;
  max-height: 600px;
  overflow: hidden;
}

.full-width-hero-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Text and Gallery Wrap Styles */
/* Text Section Styles */
.description,
.concept {
  font-size: 20px;
  line-height: 1.6; 
}

/* Description and Gallery Wrap Styles - Revised */
.description-gallery-wrap,
.concept-gallery-wrap {
  display: flex;
  flex-wrap: wrap; 
  align-items: flex-start;
}

/* Description and Concept Text Sections */
.description,
.concept {
  flex: 0 0 50%; 
  padding: 2rem;
  background-color: rgba(
    255,
    255,
    255,
    0.9
  );
  box-sizing: border-box; 
  background-color: #f5ece2;
}

/* Gallery Styles */
.gallery {
  display: flex;
  flex-wrap: wrap; 
  justify-content: space-between;
  flex: 0 0 50%; 
  box-sizing: border-box; 
  margin-left: 0;
  margin-right: 0;
}

/* Individual Image Container */
/* Gallery Image Container */
.gallery-image-container {
  flex: 0 0 calc(50% - 20px); 
  margin: 10px;
  position: relative;
  overflow: hidden;
}

.gallery-image-container::before {
  content: "";
  display: block;
  padding-top: 100%; 
}

/* Gallery Image Styles */
.gallery-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Media queries */
/* For larger screens */
@media (min-width: 1500px) {
  .gallery-image-container {
    flex: 0 0 calc(50% - 20px); 
  }
}

/* Adjust the gallery container margin if needed */
.gallery {
  margin: 20px -10px; 
}

@media (max-width: 991px) {
  /* Adjust for medium screens */
  .description,
  .concept {
    flex: 0 0 100%; /* Full width */
  }

  .gallery {
    flex: 0 0 100%; /* Full width */
  }

  .gallery-image-container {
    flex: 0 0 100%; /* Full width */
  }

  /* Make the featured image container take full width and stack below the text */
  .first-section {
    flex-direction: column; 
  }

  .featured-image-container {
    width: 100%; 
    max-width: none; 
    margin: 0 auto 1rem; 
  }

  /* Make the featured image responsive within its container */
  .featured-image {
    width: 100%; 
    height: auto; 
  }

  /* Other styles for medium screens */
  .description,
  .concept,
  .gallery {
    flex-basis: 100%;
  }

  .gallery-image-container {
    flex: 0 0 calc(50% - 20px); 
    margin-left: 10px; 
    padding-top: calc(50% - 20px); 
  }
}

@media (max-width: 767px) {
  .project-content {
    margin-top: 100vh; 
  }

  .featured-image-container {
    margin: 0 auto; 
    padding: 0.5rem;
  }

  .featured-image {
    width: 100%; 
    height: auto; 
    object-fit: cover;
  }

  /* Adjusting the text sections to stack on top of each other */
  .description,
  .concept {
    padding: 1rem; 
  }

  .gallery-image-container {
    flex: 0 0 100%;
    padding-top: 0; 
    margin-bottom: 1rem; 
  }

  .gallery-image {
    height: 100%; 
    object-fit: cover;
  }
}

/* Adjust the gallery margin to ensure even spacing */
.gallery {
  margin-left: -10px; 
  margin-right: -10px; 
}

/* Each gallery image container will have even spacing */
.gallery-image-container {
  margin-left: 10px;
  margin-right: 10px;
}

/* For very small screens where each image should take the full width */
@media (max-width: 480px) {
  .gallery-image-container {
    flex: 0 0 100%; 
    margin-top: 10px;
    padding-top: 0; 
  }
}
